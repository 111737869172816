// src/slices/leadSlice.js
import { createSlice } from "@reduxjs/toolkit";

const leadSlice = createSlice({
  name: "leads",
  initialState: {
    leads: {}, // Object to store leads with IDs as keys
  },
  reducers: {
    addLeadsDetails(state, action) {
      const { id, data } = action.payload;
      console.log("id", id);
      console.log("data", data);
      state.leads[id] = { ...state.leads[id], ...data };

      // if (state.leads[id]) {
      //   state.leads[id] = { ...state.leads[id], ...data }; // Update the existing leads
      // }
    },
  },
});

export const { addLeadsDetails } = leadSlice.actions;
export default leadSlice.reducer;
