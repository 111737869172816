// src/slices/bookingSlice.js
import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "bookings",
  initialState: {
    bookings: {}, // Object to store bookings with IDs as keys
  },
  reducers: {
    addBookingDetails(state, action) {
      const { id, data } = action.payload;
      console.log("id", id);
      console.log("data", data);
      state.bookings[id] = { ...state.bookings[id], ...data };

      // if (state.bookings[id]) {
      //   state.bookings[id] = { ...state.bookings[id], ...data }; // Update the existing bookings
      // }
    },
  },
});

export const { addBookingDetails } = bookingSlice.actions;
export default bookingSlice.reducer;
