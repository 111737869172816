// src/slices/salesRegisterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const salesRegisterSlice = createSlice({
  name: "salesRegister",
  initialState: {
    salesRegister: {}, // Object to store salesRegister with IDs as keys
  },
  reducers: {
    addSalesRegister(state, action) {
      const { id, data } = action.payload;
      console.log("id", id);
      console.log("data", data);
      state.salesRegister[id] = { ...state.salesRegister[id], ...data };

      // if (state.salesRegister[id]) {
      //   state.salesRegister[id] = { ...state.salesRegister[id], ...data }; // Update the existing salesRegister
      // }
    },
  },
});

export const { addSalesRegister } = salesRegisterSlice.actions;
export default salesRegisterSlice.reducer;
