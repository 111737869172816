// src/slices/propertySlice.js
import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
  name: "allUnitDetails",
  initialState: {
    properties: {}, // Object to store properties with IDs as keys
    editRentPurchase: {},
  },
  reducers: {
    addProperty(state, action) {
      const { id, data } = action.payload;
      console.log("Adding/updating property with ID:", id, "Data:", data);
      state.properties[id] = { ...state.properties[id], ...data };
    },
    
    editRentPurchase(state, action) {
      console.log("action.payload??", action.payload);
      state.editRentPurchase = action.payload;
    },
  },
});

export const { addProperty, editRentPurchase } = propertySlice.actions;
export default propertySlice.reducer;
