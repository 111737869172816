// src/slices/customerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: {}, // Object to store customers with IDs as keys
  },
  reducers: {
    addCustomer(state, action) {
      const { id, data } = action.payload;
      console.log("id", id);
      console.log("data", data);
      state.customers[id] = { ...state.customers[id], ...data };

      // if (state.customers[id]) {
      //   state.customers[id] = { ...state.customers[id], ...data }; // Update the existing customer
      // }
    },
  },
});

export const { addCustomer } = customerSlice.actions;
export default customerSlice.reducer;
